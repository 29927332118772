import React from "react";
import { useAppSelector } from "../hooks/redux/redux";

const AnotherComponent = () => {
  const userProfile = useAppSelector((state) => state.userProfile.userData);
  const isLoggedIn = useAppSelector((state) => state.userProfile.isLoggedIn);
  return (
    <div className="flex flex-col w-full  items-center justify-center p-8  rounded-lg shadow-md  max-w-lg mx-auto text-center">
      {isLoggedIn && userProfile ? (
        <div>
          <p className="text-xl font-bold text-gray-700 mb-4">Welcome,</p>
          <p className="text-lg text-blue-600">
            {userProfile.name || "No name provided"}
          </p>
        </div>
      ) : (
        <p className="text-lg text-red-600">No user is logged in.</p>
      )}
    </div>
  );
};

export default AnotherComponent;
