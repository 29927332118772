import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FetchLanguagesApi } from "../../api/Language";
import { addCategoryApi, editCategoryApi, fetchCategoryByIdApi } from "../../api/Category";

const CategoryForm = () => {
  const [languages, setLanguages] = useState<any[]>([]);
  const [categoryName, setCategoryName] = useState<string>("");
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [languageId, setLanguageId] = useState<string>('');

  const location = useLocation();
  const navigate = useNavigate();
  const { id } = location.state || {};

  useEffect(() => {
    const fetchCategoryData = async () => {
      if (!id) return;
      try {
        const categoryToEdit = await fetchCategoryByIdApi(id);
        if (categoryToEdit) {
          setCategoryName(categoryToEdit.name);
          setLanguageId(categoryToEdit.languageId);
          setIsEditMode(true);
        }
      } catch (error) {
        console.error("Unable to fetch book data", error);
      }
    };
    fetchCategoryData();
  }, [id]);
  

  useEffect(() => {
    const fetchLanguagesData = async () => {
      const data = await FetchLanguagesApi();
      setLanguages(data)
      }
    
    fetchLanguagesData();
  }, []);


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (isEditMode) {
        await editCategoryApi(id, categoryName, languageId);
        alert("Category updated successfully");
      } else {
        await addCategoryApi(languageId, categoryName);
        alert("Category added successfully");
      }
      navigate("/admin/category");
    } catch (error) {
      console.error("Error adding/updating category:", error);
      alert("Failed to add/update category");
    }
  };

  const handleCancel = () => {
    setCategoryName("");
    setLanguageId("");
    navigate("/admin/category");
  };

  return (
    <div className="max-w-4xl p-6 mx-auto dark:bg-gray-800">
      <div>
        <form onSubmit={handleSubmit}>
          <h1 className="text-3xl font-bold text-center text-blue-600 capitalize dark:text-white mb-4">
            {isEditMode ? "Edit Category" : "Add Category"}
          </h1>

          <div className="mb-6">
            <label className="text-gray-900 dark:text-gray-200 font-semibold">
              Category Name <span className="text-red-500">*</span>
            </label>
            <input
              required
              type="text"
              name="name"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              className="w-full px-4 py-2 mt-2  text-gray-800  bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              placeholder="Category Name"
            />
          </div>

          <div className="mb-6 w-full">
            <label className="text-gray-900 dark:text-gray-200 font-semibold">
              Language <span className="text-red-500">*</span>
            </label>
            <select
              className="w-full px-4 py-2 mt-2  text-gray-800  bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              value={languageId}
              onChange={(e) => {
               setLanguageId(e.target.value)
              }}
            >
              <option value="">Select a language</option>
              {languages.length > 0 ? (
                languages.map((language) => (
                  <option key={language.id} value={language.id}>
                    {language.name}
                  </option>
                ))
              ) : (
                <option value="">Loading languages...</option>
              )}
            </select>
          </div>

          <div className="flex space-x-4 mt-6 justify-end">
            <button
              type="submit"
              className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-gray-600"
            >
              {isEditMode ? "Edit" : "Add"}
            </button>
            <button
              type="button"
              onClick={handleCancel}
              className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-gray-600"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CategoryForm;
