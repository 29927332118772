import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export const createBookApi = async (
    LanguageId: string,
    Name: string,
    AuthorId: string,
    CategoryId: string
  ) => {
    const token = cookies.get("authToken");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/book/create`,
        {
          LanguageId,
          Name,
          AuthorId,
          CategoryId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  };
  
  export const getBooksData = async () => {
    const token = cookies.get("authToken");
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/book/getall`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  
  export const updateBookApi = async (
    Id: string,
    LanguageId: string,
    Name: string,
    AuthorId: string,
    CategoryId: string,
    Status:number,
  ) => {
    const token = cookies.get("authToken");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/book/update`,
        {
          Id,
          LanguageId,
          Name,
          AuthorId,
          CategoryId,
          Status
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  };
  
  export const getBookByIdAPi = async (Id: string) => {
    const cookies = new Cookies();
    const token = cookies.get("authToken");
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/book/getbyid`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          Id,
        },
        
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  export const publishBooksApi = async (
    bookId : string,
    languageId: string,
    name: string,
    authorId: string,
    categoryId: string,
    status:number
  ) => {
    const token = cookies.get("authToken");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/book/publishBook`,
        {
          bookId,
          languageId,
          name,authorId,
          categoryId,
          status
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  };