import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FetchpageApi } from "../../../api/Pages";
import { getSectionDataById, updateSectionApi } from "../../../api/Section";

type Page = {
  id: string;
  name: string;
};

const SectionPageForm = () => {
  const [inputValues, setInputValues] = useState<{
    name: string;
    pageId: string;
  }>({ name: "", pageId: "" });
  const navigate = useNavigate();
  const [pages, setPages] = useState<Page[]>([]); // Store books data

  const location = useLocation();
  const { id, parentId, bookId } = location.state || {};

  useEffect(() => {
    const fetchSections = async () => {
      if (!id) return;
      try {
        const section = await getSectionDataById(id);
        if (section) {
          setInputValues({
            name: section.name,
            pageId: section.pageId,
          });
        }
      } catch (error) {
        console.error('Unable to fetch section data', error);
      }
    };
  
    fetchSections();
  }, [id]);
  

  const handlefetchPagesApi = async () => {
    try {
      const result = await FetchpageApi();
      setPages(result);
    } catch (error) {
      console.error("Error fetching books:", error);
    }
  };

  useEffect(() => {
    handlefetchPagesApi();
  }, []);

  const handleSubmitSections = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    if (!inputValues.name || !inputValues.pageId) {
      alert("Please fill all the required fields");
      return;
    }

    try {
      await updateSectionApi(
        id,
        bookId,
        inputValues.name,
        inputValues.pageId,
        parentId
      );
    } catch (error) {
      console.error("unable to edit section", error);
    }
  };

  return (
    <div>
      <section className="max-w-4xl p-6 mx-auto dark:bg-gray-800">
        <h2 className="text-3xl font-bold text-center text-blue-600 capitalize dark:text-white">
          Add New Section
        </h2>

        <form>
          <div className="grid grid-rows-2 gap-6 mt-4 sm:grid-rows-2">
            <div>
              <label
                className="text-gray-900 dark:text-gray-200 font-semibold"
                htmlFor="username"
              >
                Name <span className="text-red-500">*</span>
              </label>
              <input
                required
                id="sectionname"
                type="text"
                placeholder="Section Name "
                onChange={(e) =>
                  setInputValues((prev) => ({
                    ...prev,
                    name: e.target.value,
                  }))
                }
                className=" w-full px-4 py-2 mt-2  text-gray-800  bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              />
            </div>

            <div>
              <label
                className="text-gray-900 dark:text-gray-200 font-semibold"
                htmlFor="select-Page-No"
              >
                Page No <span className="text-red-500">*</span>
              </label>
              <select
                required
                id="select-Page-No"
                onChange={(e) =>
                  setInputValues((prev) => ({
                    ...prev,
                    pageId: e.target.value,
                  }))
                }
                className=" w-full px-4 py-2 mt-2 text-gray-800  bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              >
                <option value="">-- Select Page No --</option>
                {pages.map((option: any, index: number) => (
                  <option key={index} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex justify-end gap-4 mt-6">
            <button
              className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-gray-600"
              onClick={handleSubmitSections}
            >
              Add
            </button>
            <button
              className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform  bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-gray-600"
              onClick={() => navigate("/admin/sections")}
            >
              Cancel
            </button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default SectionPageForm;
