import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getBooksData } from "../../api/Books";
import { fetchCategoriesApi } from "../../api/Category";
import { fetchAuthorsApi } from "../../api/Author";
import { FetchLanguagesApi } from "../../api/Language";
import {
  createBookRoleApi,
  getBookRoleByIdAPi,
  updateBookRoleApi,
} from "../../api/BookRole";

const BookRolesForm = () => {
  const [EntityType, setEntityType] = useState<number>();
  const [entityId, setEntityId] = useState<string | null>(null);
  const [access, setAccess] = useState<number | null>();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [entityIdOptions, setEntityIdOptions] = useState([]);
  const [roleName, setRoleName] = useState<string>("");

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || {};

  interface bookRole {
    id: number;
    name: string;
  }

  const Access: bookRole[] = useMemo(()=> [
    {
      id: 1,
      name: "Draft",
    },
    {
      id: 2,
      name: "Review",
    },
    {
      id: 3,
      name: "Approve",
    },
    {
      id: 4,
      name: "Preview",
    },
    {
      id: 5,
      name: "Publish",
    },
  ],[]);

  const EntityTypeOptions: bookRole[] = useMemo(()=> [
    {
      id: 1,
      name: "Book",
    },
    {
      id: 2,
      name: "Category",
    },
    {
      id: 3,
      name: "Author",
    },
    {
      id: 4,
      name: "Language",
    },
  ],[]);
  const [filteredAccess, setFilteredAccess] = useState<bookRole[]>(Access);

  useEffect(() => {
    const fetchBookRoleById = async () => {
      if (!id) return;
      try {
        const bookRoleToEdit = await getBookRoleByIdAPi(id);
        if (bookRoleToEdit) {
          setEntityType(
            EntityTypeOptions.find(
              (type: any) => bookRoleToEdit.entityTypeName === type.name
            )?.id
          );
          setEntityId(bookRoleToEdit.entityId);
          setRoleName(bookRoleToEdit.name)
          setAccess(
            Access.find((role: any) => bookRoleToEdit.accessName === role.name)?.id ?? null
          );
          setIsEditMode(true);
        }
      } catch (error) {
        console.error("not fetching data to edit", error);
      }
    };
    fetchBookRoleById();
  }, [id, Access, EntityTypeOptions]);

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!EntityType || (!entityId && entityId !== null) || (!access && access!==null)) {
      alert("Please fill all the required fields");
      return;
    }
    try {
      if (isEditMode) {
        await updateBookRoleApi(id, roleName, EntityType, entityId, access);
        alert("Book role Update Successfully");
      } else {
        await createBookRoleApi(roleName, EntityType, entityId, access);
        alert("New book Role Added Succesfully");
      }
      navigate("/admin/bookroles");
    } catch (error) {
      console.error(`Unable to ${isEditMode ? "update" : "add"} book`, error);
    }
  };

  useEffect(() => {
    const fetchMultipleApis = async () => {
      try {
        if (!EntityType) {
          setEntityIdOptions([]);
          return;
        }

        const entityTypeMap = {
          1: getBooksData,
          2: fetchCategoriesApi,
          3: fetchAuthorsApi,
          4: FetchLanguagesApi,
        };

        const fetchFunction =
          entityTypeMap[EntityType as keyof typeof entityTypeMap];
        if (fetchFunction) {
          const response = await fetchFunction();
          setEntityIdOptions(response);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      // Reset access when entityType changes
    };
    fetchMultipleApis();
  }, [EntityType]);

  useEffect(() => {
    if (EntityType === 1) {
      // For "Book"
      setFilteredAccess(Access); // All access roles
    } else if (EntityType) {
      // For other types, filter to show only specific roles
      setFilteredAccess(Access.filter((role) => role.name !== "Preview"));
    } else {
      setFilteredAccess([]);
    }
    // Reset access when entityType changes
  }, [EntityType, Access]);

  const handleChangeEntity = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setEntityId(value === "ALL" ? null : value);
  }

  const handleChangeAccess = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setAccess(value === "ALL" ? null : parseInt(value, 10));
  };
  
  
  return (
    <section className="max-w-3xl mx-auto p-6  dark:bg-gray-800">
      <h2 className="text-3xl font-bold text-center text-blue-600 capitalize dark:text-white">
        Add New Roles
      </h2>
      <form>
        <div className="grid grid-rows-3 gap-6 mt-4 sm:grid-rows-3">
          <div>
            <label
              className="text-gray-900 dark:text-gray-200 font-semibold"
              htmlFor="emailAddress"
            >
              Entity Type <span className="text-red-500">*</span>
            </label>
            <select
              required
              value={EntityType}
              onChange={(event) => {
                setEntityType(parseInt(event.target.value));
              }}
              className=" w-full px-4 py-2 mt-2 text-gray-800  bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
            >
              <option value="">Select Entity Type </option>
              {EntityTypeOptions.map((option: any, index: number) => (
                <option key={index} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label
              className="text-gray-900 dark:text-gray-200 font-semibold"
              htmlFor="entityId"
            >
              EntityId <span className="text-red-500">*</span>
            </label>
            <select
              required
              value={entityId === null ? "ALL" : entityId}
              onChange={handleChangeEntity}
              className=" w-full px-4 py-2 mt-2 text-gray-800  bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
            >
              <option value="">Select Entity</option>
              <option value="ALL">All</option>
              {entityIdOptions.map((option: any, index: number) => (
                <option key={index} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label
              className="text-gray-900 dark:text-gray-200 font-semibold"
              htmlFor="rolename"
            >
              Name <span className="text-red-500">*</span>
            </label>
            <input
              required
              id="rolename"
              type="text"
              placeholder="Type role name"
              value={roleName}
              onChange={(event) => {
                setRoleName(event.target.value);
              }}
              className=" w-full px-4 py-2 mt-2  text-gray-800  bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
            />
          </div>

          <div>
            <label
              className="text-gray-900 dark:text-gray-200 font-semibold"
              htmlFor="passwordConfirmation"
            >
              Access <span className="text-red-500">*</span>
            </label>
            <select
              required
              id="category"
              value={access === null ? "ALL" : access}
              onChange={handleChangeAccess}
              className=" w-full px-4 py-2 mt-2 text-gray-800  bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
            >
              <option value="">Select Role</option>
              <option value="ALL">All</option>
              {filteredAccess.map((option: any, index: number) => (
                <option key={index} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="flex justify-start gap-4 mt-6">
          <button
            className="px-8 py-2.5 leading-5 text-white font-semibold transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-gray-600"
            onClick={handleSubmit}
          >
            {isEditMode ? "Update" : "Add"}
          </button>
          <button
            className="px-8 py-2.5 leading-5 text-white font-semibold transition-colors duration-300 transform  bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-gray-600"
            onClick={() => navigate("/admin/bookroles")}
          >
            Cancel
          </button>
        </div>
      </form>
    </section>
  );
};

export default BookRolesForm;
