import axios from "axios";
import Cookies from "universal-cookie";
const apiUrl = process.env.REACT_APP_API_URL;
const cookies = new Cookies();

//to ADD author

export const addAuthorApi = async (name: string, language: string) => {
  const token = cookies.get("authToken");

  try {
    const response = await axios.post(
      `${apiUrl}/api/author/add`,
      {
        LanguageId: language,
        Name: name,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);

    throw error;
  }
};

export const fetchAuthorsApi = async () => {
  const token = cookies.get("authToken");

  try {
    const response = await axios.get(`${apiUrl}/api/author/getauthors`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

//fetch AuthorID

export const fetchAuthorByIdApi = async (Id: string) => {
  const cookies = new Cookies();
  const token = cookies.get("authToken");

  try {
    const response = await axios.get(
      `${apiUrl}/api/author/getauthorid/${Id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          Id,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching author by ID:", error);
    return false;
  }
};

export const deleteAuthorApi = async (id: string) => {
  const token = cookies.get("authToken");

  try {
    const response = await axios.delete(`${apiUrl}/api/author/delete`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting lang:", error);
    throw error;
  }
};

export const editAuthorApi = async (
  id: string,
  name: string,
  LanguageId: string
) => {
  const token = cookies.get("authToken");

  try {
    const response = await axios.post(
      `${apiUrl}/api/author/edit`,
      {
        id: id,
        name: name,
        languageId: LanguageId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const publishAuthorApi = async (
  authorId: string,
  languageId: string,
  name: string,
  status: number
) => {
  const token = cookies.get("authToken");

  try {
    const response = await axios.post(
      `${apiUrl}/api/author/publishAuthor`,

      {
        authorId,
        languageId,
        name,
        status
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error(error);

    throw error;
  }
};