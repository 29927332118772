import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export const createSectionApi = async (
    BookId: string,
    Name: string,
    ParentId?: string | null,
    PageId?: string
  ) => {
    const token = cookies.get("authToken");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/section/create`,
        {
            BookId,
            Name,
            ParentId : ParentId ?? null,
            PageId 
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  };

  export const updateSectionApi = async (
    Id: string,
    BookId: string,
    Name: string,
    ParentId: string | null,
    PageId: string
  ) => {
    const token = cookies.get("authToken");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/section/update`,
        {
          Id,
          BookId,
          Name,
          ParentId : ParentId ?? null,
          PageId
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  };

  export const getsectionsData = async () => {
    const token = cookies.get("authToken");
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/section/getall`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  export const getSectionDataById =  async (Id:string) =>{
    const token = cookies.get("authToken");
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/book/getbyid`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          Id,
        },
        
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }