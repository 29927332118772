import React, { createContext, useContext, useState, ReactNode } from 'react';

interface Book {
  id: string;
  authorId: string;
  bookname: string;
  categoryId: string;
  languageId: string;

}
interface CommonContextType {
  selectedAuthorLanguage: string;
  setSelectedAuthorLanguage: React.Dispatch<React.SetStateAction<string>>;
  filteredAuthors: any[];
  setFilteredAuthors: React.Dispatch<React.SetStateAction<any[]>>;
  selectedcategoryLanguage: string;
  setSelectedcategoryLanguage: React.Dispatch<React.SetStateAction<string>>;
  filteredcategories: any[];
  setFilteredcatrgories: React.Dispatch<React.SetStateAction<any[]>>;
  selectedcatLanguageName: { id: string; name: string }[];
  setSelectedcatLanguageName: React.Dispatch<React.SetStateAction<{ id: string; name: string }[]>>;
  selectedLanguage: string;
  setSelectedLanguage: React.Dispatch<React.SetStateAction<string>>;
  selectedAuthors: string;
  setSelectedAuthors: React.Dispatch<React.SetStateAction<string>>;
  selectedCategories: string;
  setSelectedCategories: React.Dispatch<React.SetStateAction<string>>;
  filteredBooks: any[];
  setFilteredBooks: React.Dispatch<React.SetStateAction<any[]>>;
}

const CommonContext = createContext<CommonContextType | undefined>(undefined);

interface CommonProviderProps {
  children: ReactNode;
}

export const CommonProvider: React.FC<CommonProviderProps> = ({ children }) => {
  const [selectedAuthorLanguage, setSelectedAuthorLanguage] = useState<string>('');
  const [filteredAuthors, setFilteredAuthors] = useState<any[]>([]);
  const [selectedcategoryLanguage, setSelectedcategoryLanguage] = useState<string>('');
  const [filteredcategories, setFilteredcatrgories] = useState<any[]>([]);
  const [selectedcatLanguageName, setSelectedcatLanguageName] = useState<{ id: string; name: string }[]>([]);
  const [selectedAuthors, setSelectedAuthors] = useState<string>("");
  const [selectedCategories, setSelectedCategories] = useState<string>("");
  const [selectedLanguage, setSelectedLanguage] = useState<string>("");
  const [filteredBooks, setFilteredBooks] = useState<Book[]>([]);



  return (
    <CommonContext.Provider value={{

      selectedAuthorLanguage,
      setSelectedAuthorLanguage,
      filteredAuthors,
      setFilteredAuthors,

      selectedcategoryLanguage,
      setSelectedcategoryLanguage,
      filteredcategories,
      setFilteredcatrgories,
      selectedcatLanguageName,
      setSelectedcatLanguageName,


      selectedLanguage,
      setSelectedLanguage,
      selectedAuthors,
      setSelectedAuthors,
      selectedCategories,
      setSelectedCategories,
      filteredBooks,
      setFilteredBooks
    }}>
      {children}
    </CommonContext.Provider>
  );
};

export const useCommonContext = (): CommonContextType => {
  const context = useContext(CommonContext);
  if (!context) {
    throw new Error('useCommonContext must be used within a CommonProvider');
  }
  return context;
};
