import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PageProvider } from "./Components/admin/pages/PageContext";
import { CommonProvider } from "./Components/admin/Commoncontaxt";
import ThemeProvider from "./Components/theme/ThemeProvider";
import { Provider } from "react-redux";
import store from "./Components/hooks/redux/store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider>
        <PageProvider>
          <CommonProvider>
            <App />
          </CommonProvider>
        </PageProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
