import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();



export const createBookAccessApi = async (
  roleId: string,
  userId: string
) => {
  const token = cookies.get("authToken");
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/bookaccess/create`,
      {
        roleId,
        userId
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const updateBookAccessApi = async (
    roleId: string,
    userId: string
) => {
  const token = cookies.get("authToken");
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/bookaccess/update`,
      {
        roleId,
        userId
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const getAllBookAccessApi = async (
) => {
  const token = cookies.get("authToken");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/bookaccess/get`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  
    return response.data;
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const getBookAccessByIdAPi = async (userId: string) => {
  const cookies = new Cookies();
  const token = cookies.get("authToken");
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/bookaccess/getbyid`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        userId,
      },

    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};


