import axios from "axios";
import Cookies from "universal-cookie";
const apiUrl = process.env.REACT_APP_API_URL + "/api";

const cookies = new Cookies();

export const AddpageApi = async (
  pageNo: string,
  sequence: number,
  content: string,
  bookId: string
) => {
  const token = cookies.get("authToken");
  try {
    const response = await axios.post(
      `${apiUrl}/page/add`,
      {
        pageNo,
        sequence,
        content,
        bookId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error adding page:", error);
    throw error;
  }
};

export const FetchpageApi = async () => {
  const token = cookies.get("authToken");
  try {
    const response = await axios.get(`${apiUrl}/page/getpage`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });


    if (response.data) {
      // console.log("Data received:", response.data);
      return response.data;
    } else {
      console.log("No data received.");
    }

    return [];
  } catch (error) {
    console.error("Error fetching pages:", error);
    throw error;
  }
};

export const EditPageApi = async (
  id: string | null,
  bookId: string | null,
  pageNo: string,
  sequence: number,
  content: string
): Promise<any> => {
  const token = cookies.get("authToken");

  try {
    const response = await axios.post(
      `${apiUrl}/page/edit`,
      {
        id: id || undefined,
        bookId: bookId || undefined,
        pageNo: pageNo,
        sequence: Number(sequence),
        content: content.trim()

      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Error editing Pages:", error.response?.data);
      console.error("Error status:", error.response?.status);
    } else {
      console.error("Unexpected error:", error);
    }
    throw error;
  }
};




export const UpdatePageSequenceApi = async (
  id: string,
  sequence: number
): Promise<any> => {
  const token = cookies.get("authToken");

  try {
    const response = await axios.post(
      `${apiUrl}/page/edit`,
      {
        id,
        sequence
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Error updating page sequence:", error.response?.data);
      console.error("Error status:", error.response?.status);
    } else {
      console.error("Unexpected error:", error);
    }
    throw error;
  }
};














export const getPageByIdApi = async (id: string) => {
  const cookies = new Cookies();
  const token = cookies.get("authToken");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/page/getbyid`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          id,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getPagesByBookIdApi = async (bookId: string) => {
  const cookies = new Cookies();
  const token = cookies.get("authToken");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/page/getbybookid`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          bookId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
