import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const apiUrl = process.env.REACT_APP_API_URL + "/api";

export const addCategoryApi = async (language: string, name: string) => {
  const token = cookies.get("authToken");
  try {
    const response = await axios.post(
      `${apiUrl}/category/add`,
      {
        LanguageId: language,
        Name: name,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error(error);

    throw error;
  }
};

export const fetchCategoriesApi = async () => {
  const token = cookies.get("authToken");

  try {
    const response = await axios.get(`${apiUrl}/category/getcategories`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const deleteCategoryApi = async (id: string) => {
  const token = cookies.get("authToken");

  try {
    const response = await axios.delete(`${apiUrl}/category/delete`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error deleting lang:", error);
    throw error;
  }
};

//to EDIT category

export const editCategoryApi = async (
  id: string,
  name: string,
  LanguageId: string
) => {
  const token = cookies.get("authToken");

  try {
    const response = await axios.post(
      `${apiUrl}/category/edit`,
      {
        id: id,
        LanguageId: LanguageId,
        name: name,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error editing category:", error);
    throw error;
  }
};



export const fetchCategoryByIdApi = async (Id: string) => {
  const cookies = new Cookies();
  const token = cookies.get("authToken");
  try {
    const response = await axios.get(
      `${apiUrl}/category/getbyid`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          Id,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching category by ID:", error);
    return false;
  }
};





































export const publishCategoriesApi = async (
  CategoryId: string,
  LanguageId: string,
  Name: string,
  Status: number
) => {
  const token = cookies.get("authToken");

  try {
    const response = await axios.post(
      `${apiUrl}/category/publishcategory`,
      {
        CategoryId,
        LanguageId,
        Name,
        Status,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error(error);

    throw error;
  }
};
